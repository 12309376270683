<template>
  <div class="max-w-7xl">
    <h1 class="mb-6 border-b-2 border-primary px-5 pb-2 font-fancy text-xl font-bold text-primary">Which sectors would you consider working in?</h1>
    <div class="mb-10 columns-2 space-y-3 px-5">
      <Checkbox v-for="(sectorName, sector) in sectors" :key="sector" :modelValue="store.sectors.includes(sector)" :name="sectorName" @toggle="store.toggleSector(sector)"></Checkbox>
    </div>
    <h1 class="mb-6 border-b-2 border-primary px-5 pb-2 font-fancy text-xl font-bold text-primary">Would you be capable of working in an environment where Dutch is the main spoken language?</h1>
    <div class="mb-10 space-y-3 px-5">
      <Checkbox @toggle="store.toggleDutch" :modelValue="store.dutch" :name="'Take companies into consideration that use Dutch as their main language'"></Checkbox>
    </div>
  </div>
</template>

<script setup>
import { usePreferencesStore } from '../store/PreferencesStore'
import Checkbox from './Checkbox.vue'
import sectors from '../services/sectors.json'

const store = usePreferencesStore()
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

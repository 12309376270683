<template>
  <div class="relative border-b border-b-gray-300">
    <div
      class="absolute left-1/2 -bottom-[66px] inline-block -translate-x-1/2 transform whitespace-nowrap rounded-sm bg-primary px-6 py-2 font-primary text-xl tracking-widest text-white lg:-bottom-[22px] lg:left-14 lg:translate-x-0"
    >
      <div class="absolute left-1/2 -bottom-1 h-5 w-5 -translate-x-1/2 rotate-45 transform border-b-8 border-r-8 border-primary"></div>
      <p>MAKE UP MY MIND</p>
    </div>
    <div class="flex justify-center lg:justify-end">
      <p
        v-for="(pageName, pageIndex) in pages"
        :key="pageIndex"
        class="text-md px-4 py-6 font-primary tracking-widest hover:cursor-pointer"
        :class="pageIndex === page ? 'bg-primary text-white' : 'text-primary hover:bg-purple-100'"
        @click="$emit('movePage', pageIndex)"
      >
        {{ pageName }}
      </p>
    </div>
  </div>
</template>

<script setup>
defineProps({
  page: Number,
})
let pages = ['Sectors', 'Attributes', 'Results']
</script>

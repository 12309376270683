<template>
  <div class="lg:max-w-7xl">
    <!-- <LayoutHorizontalTabs :tabs="tabs">
      <TabPanel></TabPanel>
      <TabPanel></TabPanel>
    </LayoutHorizontalTabs> -->
    <div class="fixed left-1/2 bottom-5 z-20 -translate-x-1/2 transform rounded-md border-2 border-primary bg-white p-5 lg:left-5 lg:translate-x-0">
      <Checkbox class="whitespace-nowrap" :name="'Show extended company info'" :modelValue="extendedInfo" @toggle="extendedInfo = !extendedInfo"></Checkbox>
    </div>
    <p class="mb-5 px-5 text-center font-fancy text-sm font-bold italic text-primary">
      Below you'll find companies that might interest you for every section of the day. Click any logo to read more about the company!
    </p>
    <div class="grid gap-y-10 lg:grid-cols-2 lg:grid-rows-2 lg:gap-x-16 lg:gap-y-20">
      <div class="flex flex-col items-center" v-for="(description, category) in categories" :key="category">
        <h1 class="w-full border-0 border-b-2 border-primary pb-2 text-center font-fancy text-xl font-bold text-primary lg:w-auto lg:px-5">{{ description }}</h1>
        <div class="flex w-screen flex-col divide-y-2 divide-gray-200 px-3 lg:w-auto lg:max-w-3xl lg:px-0">
          <div v-for="company in suggestedCompanies[category]" :key="company.company" class="flex flex-col items-center space-y-5 py-10 lg:flex-row lg:space-y-0 lg:py-5">
            <div class="flex flex-col items-center space-y-5">
              <a :href="company.link" target="_blank" class="w-[175px]">
                <img :src="getLogoUrl(company.company)" class="mx-auto max-h-[90px] max-w-[125px] hover:opacity-60 lg:max-h-[70px] lg:max-w-[125px]" />
              </a>
              <BaseBadge class="font-fancy" :variant="'success'">{{ company.score }}/7 attributes</BaseBadge>
              <div v-if="extendedInfo">
                <p class="text-center font-fancy text-primary lg:text-left">Sectors</p>
                <p class="text-sm text-primary lg:hidden">{{ company.sectors.join(', ') }}</p>
                <p class="hidden text-sm text-primary lg:block" v-for="sector in company.sectors" :key="sector">{{ sector }}</p>
              </div>
            </div>
            <div class="ml-2 text-center lg:flex-1 lg:text-left">
              <template v-if="company.attributes.matched.length + company.attributes.unmatched.length > 0">
                <p class="font-fancy text-sm font-bold italic text-primary" v-if="extendedInfo || company.attributes.matched.length > 0">{{ company.fullName }} is a company..</p>
                <p class="font-fancy font-normal text-primary" v-for="attributeIndex in company.attributes.matched" :key="attributeIndex">{{ attributes[attributeIndex] }}.</p>
                <div v-if="extendedInfo">
                  <p class="font-normal italic text-slate-500" v-for="attributeIndex in company.attributes.unmatched" :key="attributeIndex">{{ attributes[attributeIndex] }}.</p>
                </div></template
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-20 mb-10 flex flex-col items-center">
      <h1 class="w-full border-b-2 border-primary pb-2 text-center font-fancy text-xl font-bold text-primary lg:w-auto lg:px-5">Dinner suggestions</h1>
      <div class="flex w-screen flex-col divide-y-2 divide-gray-200 px-3 lg:w-auto lg:max-w-3xl lg:px-0">
        <div v-for="company in suggestedCompanies.diner" :key="company.company" class="flex flex-col items-center space-y-5 py-5 lg:flex-row lg:space-y-0">
          <div class="flex flex-col items-center space-y-5">
            <a :href="company.link" target="_blank" class="w-[175px]">
              <img :src="getLogoUrl(company.company)" class="mx-auto max-h-[90px] max-w-[125px] hover:opacity-60 lg:max-h-[70px] lg:max-w-[125px]" />
            </a>
            <BaseBadge class="font-fancy" :variant="'success'">{{ company.score }}/7 attributes</BaseBadge>
            <div v-if="extendedInfo">
              <p class="text-center font-fancy text-primary lg:text-left">Sectors</p>
              <p class="text-sm text-primary lg:hidden">{{ company.sectors.join(', ') }}</p>
              <p class="hidden w-[175px] text-sm text-primary lg:block" v-for="sector in company.sectors" :key="sector">{{ sector }}</p>
            </div>
          </div>
          <div class="ml-2 text-center lg:flex-1 lg:text-left">
            <template v-if="company.attributes.matched.length + company.attributes.unmatched.length > 0">
              <p class="font-fancy text-sm font-bold italic text-primary" v-if="extendedInfo || company.attributes.matched.length > 0">{{ company.fullName }} is a company..</p>
              <p class="font-fancy font-normal text-primary" v-for="attributeIndex in company.attributes.matched" :key="attributeIndex">{{ attributes[attributeIndex] }}.</p>
              <div v-if="extendedInfo">
                <p class="font-normal italic text-slate-500" v-for="attributeIndex in company.attributes.unmatched" :key="attributeIndex">{{ attributes[attributeIndex] }}.</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePreferencesStore } from '../store/PreferencesStore'
import Checkbox from './Checkbox.vue'
import companies from '../services/companies.json'
import { computed, ref } from 'vue'
import attributes from '../services/attributes.json'
import sectors from '../services/sectors.json'
import BaseBadge from './BaseBadge.vue'
import LayoutHorizontalTabs from './LayoutHorizontalTabs.vue'
import { TabPanel } from '@headlessui/vue'

// const tabs = [{ name: 'Morning' }, { name: 'Afternoon' }, { name: 'Dinner' }]
const tabs = [{ name: 'Cases' }, { name: 'Fair' }, { name: 'Speeddates' }, { name: 'Dinner' }]

const store = usePreferencesStore()

const extendedInfo = ref(false)

// companies.forEach((company) => {
//   console.log('Fetching for', company.company)
//   getLogoUrl(company.company)
// })

function getLogoUrl(company) {
  return require(`../assets/logos/${company}.png`)
}

function partition(array, filter) {
  let pass = [],
    fail = []
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e))
  return [pass, fail]
}

let categories = {
  morningCase: 'Morning case suggestions',
  afternoonCase: 'Afternoon case suggestions',
  morningSpeeddates: 'Morning speeddates suggestions',
  afternoonSpeeddates: 'Afternoon speeddates suggestions',
}

const suggestedCompanies = computed(() => {
  let suggestedCompanies = {
    morningCase: [],
    morningSpeeddates: [],
    afternoonCase: [],
    afternoonSpeeddates: [],
    diner: [],
  }

  let languageFilteredCompanies = store.dutch ? companies : companies.filter((company) => company.english)
  let scoredCompanies = languageFilteredCompanies
    .map((company) => {
      let scoredCompany = { ...company }
      scoredCompany.score = 0
      scoredCompany.attributes = {
        matched: [],
        unmatched: [],
      }
      for (let i = 1; i <= attributes.length; i++) {
        let attributeIndex = i - 1
        if (scoredCompany[`attr${attributeIndex + 1}`] === 1) {
          if (store.attributes.includes(attributeIndex)) {
            scoredCompany.score += 1
            scoredCompany.attributes.matched.push(attributeIndex)
          } else scoredCompany.attributes.unmatched.push(attributeIndex)
        }
      }
      scoredCompany.sectors = []
      Object.entries(sectors).forEach(([sector, sectorName]) => {
        if (company[sector] === 1) scoredCompany.sectors.push(sectorName)
      })
      scoredCompany.availableSlots = company.morningSpeeddates + company.afternoonSpeeddates + company.afternoonCase + company.afternoonFair + company.morningCase + company.morningFair + company.diner
      scoredCompany.sectorAccurate = store.sectors.some((sector) => company[sector] === 1)
      return scoredCompany
    })
    .sort((a, b) => {
      return b.score - a.score || a.availableSlots - b.availableSlots
    })
  console.log(scoredCompanies)

  const [sectorAccurateCompanies, backupCompanies] = partition(scoredCompanies, (e) => e.sectorAccurate)

  // Step 1 | Morning Case - Afternoon Case
  function assignCompanies(slots) {
    let companySets = [sectorAccurateCompanies, backupCompanies]
    companySets.forEach((companies) => {
      companies.forEach((company) => {
        let assignedCompany = false
        slots.forEach((slot) => {
          if (company[slot] && suggestedCompanies[slot].length < 3 && !assignedCompany) {
            suggestedCompanies[slot].push(company)
            assignedCompany = true
          }
        })
      })
    })
  }

  assignCompanies(['morningCase', 'afternoonCase'])
  assignCompanies(['morningSpeeddates', 'afternoonSpeeddates'])
  assignCompanies(['diner'])

  return suggestedCompanies
})
</script>

import { defineStore } from 'pinia'
export const usePreferencesStore = defineStore('product', {
  state: () => ({
    dutch: true,
    sectors: [],
    attributes: [],
  }),
  getters: {},
  actions: {
    toggleDutch() {
      this.dutch = !this.dutch
    },
    toggleSector(sector) {
      if (!this.sectors.includes(sector)) this.sectors.push(sector)
      else {
        const index = this.sectors.indexOf(sector)
        this.sectors.splice(index, 1)
      }
    },
    addAttribute(attributeIndex) {
      if (this.attributes.includes(attributeIndex)) return
      if (this.attributes.length === 7) return
      this.attributes.push(attributeIndex)
    },
    removeAttribute(attributeIndex) {
      this.attributes.splice(attributeIndex, 1)
    },
  },
  persist: {
    enabled: true,
  },
})

<template>
  <div class="max-w-7xl">
    <h1 class="mb-6 border-b-2 border-primary px-5 pb-2 font-fancy text-xl font-bold text-primary">Which 7 attributes match you most? I want to work for a company..</h1>
    <div class="px-2 lg:px-5">
      <p class="mb-2 font-fancy text-sm font-bold italic text-primary">Select 7 attributes by clicking them, the order is not important.</p>
      <div class="flex">
        <div class="max-h-[48vh] w-1/2 overflow-y-scroll scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-primary scrollbar-track-rounded-full scrollbar-thumb-rounded-full lg:pr-4">
          <p
            v-for="(attribute, attributeIndex) in attributes"
            :key="attributeIndex"
            class="cursor-pointer border-b border-gray-300 px-5 py-3 text-primary last:border-0 lg:hover:opacity-60"
            :class="store.attributes.includes(attributeIndex) ? 'italic line-through opacity-50' : ''"
            @click="store.addAttribute(attributeIndex)"
          >
            {{ attribute }}
          </p>
        </div>
        <div class="max-h-[48vh] w-1/2 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-primary scrollbar-track-rounded-full scrollbar-thumb-rounded-full lg:pl-10">
          <p
            v-for="attributeIndex in 7"
            :key="attributeIndex"
            class="cursor-pointer border-b border-gray-300 px-5 py-3 text-primary lg:hover:opacity-60"
            @click="store.removeAttribute(attributeIndex - 1)"
          >
            <span class="mr-0">{{ attributeIndex }} • </span> {{ store.attributes.length >= attributeIndex ? attributes[store.attributes[attributeIndex - 1]] : '' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePreferencesStore } from '../store/PreferencesStore'
import attributes from '../services/attributes.json'
const store = usePreferencesStore()
</script>

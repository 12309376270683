<template>
  <div class="relative flex h-screen flex-col">
    <Header :page="page" @movePage="(pageIndex) => navigatePage(pageIndex)"></Header>
    <div class="mt-20 flex-1 lg:mt-16">
      <Transition name="fade" mode="out-in">
        <KeepAlive>
          <component :is="pages[page]" class="mx-auto w-full flex-1"></component>
        </KeepAlive>
      </Transition>
    </div>
    <Navigation @nextPage="navigatePage(page + 1)" @previousPage="navigatePage(page - 1)" :page="page"></Navigation>
  </div>
</template>

<script setup>
import { usePreferencesStore } from './store/PreferencesStore'
import { ref } from 'vue'
import Attributes from './components/Attributes.vue'
import Sectors from './components/Sectors.vue'
import Header from './components/Header.vue'
import Navigation from './components/Navigation.vue'
import Results from './components/Results.vue'
const store = usePreferencesStore()

let page = ref(0)
let pages = [Sectors, Attributes, Results]

function navigatePage(pageIndex) {
  if (pageIndex <= page.value) {
    page.value = pageIndex
    return
  }
  if (pageIndex === 2) {
    if (store.sectors.length == 0) {
      alert('Please select at least one sector!')
      page.value = 0
      return
    }
    if (store.attributes.length != 7) {
      alert('Please select 7 attributes!')
      page.value = 1
      return
    }
    page.value = 2
  } else if (page.value === 0) {
    if (store.sectors.length == 0) alert('Please select at least one sector!')
    else page.value = pageIndex
  } else if (page.value === 1) {
    if (store.attributes.length != 7) alert('Please select 7 attributes!')
    else page.value = pageIndex
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

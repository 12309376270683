<script setup>
defineProps({
  variant: String,
})

const variantClasses = {
  success: 'bg-green-100 text-green-800',
  warning: 'bg-yellow-100 text-yellow-800',
  danger: 'bg-red-100 text-red-800',
}
</script>

<template>
  <span :class="['inline-flex items-center rounded-full px-2.5 py-1 text-xs font-medium', variantClasses[variant]]">
    <slot></slot>
  </span>
</template>
